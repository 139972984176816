<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">
          {{ $t("After-sale order details") }}
        </p>
      </div>

      <div class="rightSide_inner_header">
        <div class="rightSide_order">
          <p>{{ $t("After-sale order number") }}：</p>
          <p>{{ info.after_item }}</p>
        </div>
      </div>
      <!-- <div style="margin-top: 20px">
				<el-steps :space="800" :active="2" finish-status="success">
					<el-step title="退回次品" description="2021-07-16 11:34"> </el-step>
					<el-step title="审核未通过" description="2021-07-17 11:35"></el-step>
				</el-steps>
			</div> -->
      <div class="table_style">
        <el-table
          ref="multipleTable"
          header-align="center"
          border
          class="main-table"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="Image" prop="goodsImg" align="center">
            <template slot-scope="scope">
              <img
                v-if="scope.row.is_presell===1"
                :src="scope.row.presell_goods_img"
                height="100"
                width="100"
              />
              <img
                v-else
                :src="`http://hwimg.xmvogue.com/thumb/${scope.row.goods_code}.jpg?x-oss-process=style/800`"
                height="100"
                width="100"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="goods_code"
            :label="$t('goods_code')"
            align="center"
          />

          <el-table-column
            prop="goods_name"
            :label="$t('Name')"
            align="center"
          />
          <el-table-column prop="num" :label="$t('Quantity')" align="center" />
          <el-table-column
            prop="reason"
            :label="$t('reason for')"
            align="center"
          />

          <el-table-column :label="$t('Courier Receipt')" align="center">
            <template slot-scope="scope">
              <img
                :src="item"
                height="60"
                v-for="(item, index) in scope.row.imagesArr"
                :key="index"
              />
            </template>
          </el-table-column>

          <el-table-column :label="$t('product picture')" align="center">
            <template slot-scope="scope">
              <img
                :src="item"
                height="60"
                v-for="(item, index) in scope.row.goodsImgArr"
                :key="index"
              />
            </template>
          </el-table-column>

          <el-table-column prop="status" :label="$t('status')" align="center">
            <template v-slot="{row}">
              <p v-if="row.status === 0">{{ $t("Pending review") }}</p>
              <p v-if="row.status === 1">{{ $t("Refuse") }}</p>
              <p v-if="row.status === 2">{{ $t("Agree") }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="remarks" :label="$t('reason for')" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="rightSide_info">
        <p style="font-size: 18px; color: #218da0">{{ $t("After sales information") }}</p>
        <div style="margin-top: 15px">
          <div class="rightSide_info_title">
            <p>{{ $t("After-sale order number") }}：</p>
            <p>{{ info.after_item }}</p>
          </div>
          <div class="rightSide_info_title">
            <p>{{ $t("After-sales type") }}：</p>

            <p v-if="info.order_pack_type == 0">{{ $t("Quality Problems") }}</p>
            <p v-if="info.order_pack_type == 1">{{ $t("Shipping Damage") }}</p>
          </div>
          <div class="rightSide_info_title">
            <p>{{ $t("amount of goods") }}：</p>
            <p>{{ info.quantity }}</p>
          </div>
          <div class="rightSide_info_title">
            <p>{{ $t("Review progress") }}：</p>
            <p v-if="info.status == 0">{{ $t("Pending review") }}</p>
            <p v-if="info.status == 1">{{ $t("Partially passed") }}</p>
            <p v-if="info.status == 2">{{ $t("All passed") }}</p>
            <p v-if="info.status == 3">{{ $t("Reject all") }}</p>
          </div>
          <div class="rightSide_info_title">
            <p>{{ $t("Contact person") }}：</p>
            <p>customer service</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAterGoodsInfoById } from "@/api/order/order.js";

export default {
  components: {},
  data() {
    return {
      tableData: [],
      info: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      var query=this.$route.query;
      let id=query.id;
      let list=[];
      getAterGoodsInfoById(id).then((res) => {
        let theData=res.data.data;
        let OrderAfter=theData.OrderAfter;

        let OrderGoodsAfter=theData.OrderGoodsAfter;
        for (let i=0; i < OrderGoodsAfter.length; i++) {
          OrderGoodsAfter[i].imagesArr=OrderGoodsAfter[i].images.split(",");
          OrderGoodsAfter[i].goodsImgArr=
            OrderGoodsAfter[i].goods_img.split(",");
        }

        // let imgArr = theData.images.split(",");
        // theData.imgArr = imgArr;
        // this.info = theData;
        // list.push(theData);
        this.info=OrderAfter;
        this.tableData=OrderGoodsAfter;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none
}

.rightSide {
  width: 1320px;
  height: 1000px;
  overflow: auto;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  &_inner {
    width: 1218px;
    height: 100%;
    padding-top: 20px;

    &_title {
      width: 1218px;
      height: 68px;
    }

    &_header {
      width: 1218px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &_order {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: #333333;
  }

  &_info {
    width: 1218px;
    height: 228px;
    margin-top: 50px;

    &_title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      font-size: 16px;
      color: #757575;
    }
  }
}

.table_style {
  width: 1218px;
  margin-top: 40px;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
